import { find, findAll } from "../utils/dom";

const Patrocinadores = () => {


	/** Função que verifica a existência de um Realizador */
	function existeRealizador() {
		const Realizadores = findAll('[js-realizador]');
		if (!Realizadores) return;

		Realizadores.forEach((Relizador) => {
			const imgsLogos = Relizador.querySelectorAll(".logos img");
			if (imgsLogos && imgsLogos.length == 0) {

				Relizador.style.display = "none";
			}
		})

	}

	/** Função que verifica a existência de um Apoiador */
	function existeApoiador() {
		const Apoiadores = findAll('[js-apoiador]');
		if (!Apoiadores) return;

		Apoiadores.forEach((Apoiador) => {
			const imgsLogos = Apoiador.querySelectorAll(".logos img");
			if (imgsLogos && imgsLogos.length == 0) {

				Apoiador.style.display = "none";
			}
		})

	}


	existeApoiador();
	existeRealizador();

}

export default Patrocinadores;